<template>
    <DataTable :headers="headers"
           :data="items"
           :expandable="expandable"
           :editable="editable"
           :pagination="pagination"
           :title="$t('systems')"
           type="system" />
</template>


<script>
// insert deploy all button
import { mapActions } from 'vuex'
import { rest } from '../_helpers'
import DataTable from '@/components/Table.vue'

export default {
    data() {
        return {
            pagination: {
                sortBy: ['projectName'],
                rowsPerPage: 25,
            },
            headers: [
                {
                    text: this.$t('project.name'),
                    align: 'left',
                    sortable: true,
                    value: 'projectName',
                    type: 'string',
                },
                {
                    text: this.$t('camera.name'),
                    align: 'left',
                    sortable: true,
                    value: 'camNo',
                    type: 'string',
                },
                {
                    text: this.$t('computer.name'),
                    align: 'left',
                    sortable: true,
                    value: 'computerName',
                    type: 'link',
                    linkName: item => (item.computerName ? item.computerName : 'Unknown'),
                    link: item => {
                        this.$router.push(`/system/${item.id}`)
                    },
                },
                {
                    text: this.$t('version'),
                    align: 'left',
                    sortable: true,
                    value: 'version',
                    type: 'string',
                },
                {
                    text: this.$t('deploy'),
                    align: 'center',
                    sortable: false,
                    value: 'invId',
                    type: 'link',
                    width: '3em',
                    link: item => {
                        rest.deploy(item.id)
                    },
                    linkIf: item => item.architecture === 1,
                    linkIcon: 'mdi-update',
                },
            ],
            items: [],
            expandable: false,
            editable: false,
        }
    },

    components: {
        DataTable,
    },

    methods: {
        ...mapActions({
            showSpinner: 'page/showSpinner',
            setNew: 'input/setNew',
            resetNew: 'input/resetNew',
        }),

        getRows() {
            const payload = {
                where: { enabled: true, computer: { $not: null } },
                attributes: [
                    'id',
                    'camNo',
                    ['version', 'architecture'],
                    ['project.name', 'projectName'],
                    ['computerData.computer.name', 'computerName'],
                    ['computerData.computer.MKLapseVer', 'version'],
                ],
                includes: [
                    {
                        type: 'project',
                        attributes: ['name'],
                    },
                    {
                        type: 'computerData',
                    },
                ],
            }

            rest.getRows('system', payload)
                .then(items => {
                    this.items = items
                })
                .finally(() => {
                    this.showSpinner(false)
                })
        },
    },

    mounted() {
        this.showSpinner(true)
    },

    created() {
        this.getRows()
    },
}
</script>
